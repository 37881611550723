import { useState } from "react";
import { Drawer } from "../../../utils/offcanvas";
import { ButtonMenu, EllipsisMenu } from "../../../utils/ellipsis_menu";
import { useApiMutation, usePageData } from "../../../utils/api";
import { clickPrimaryButton, useDevTool } from "../../../utils/dev_tools";
import PackageForm from "./package_form";
import { PlatformIcon } from "../../campaigns/utils";
import { genericError } from "../../../utils/errors";
import { toast } from "react-toastify";
import { Badge, Breadcrumb, Tab, Tabs } from "react-bootstrap";
import { startCase } from "lodash";
import { DealsTab } from "./deals_tab";
import { useFetchCreatorsDrawer } from "./fetch_creators_drawer";
import { formatCurrency } from "../../../utils/helpers";

type Status =
  | "onboarding_in_progress"
  | "onboarding_complete"
  | "packages_submitted"
  | "package_selected"
  | "campaign_in_progress"
  | "campaign_completed";

class CampaignStatus {
  campaign: { status: Status };
  currentIndex: number;
  status: string;

  order = [
    "onboarding_in_progress",
    "onboarding_complete",
    "packages_submitted",
    "package_selected",
    "campaign_in_progress",
    "campaign_completed",
  ];

  constructor(campaign) {
    this.campaign = campaign;
    this.status = campaign.status;
    this.currentIndex = this.order.findIndex((s) => s === this.status);
  }

  isAtLeast(target: Status) {
    return this.currentIndex >= this.order.findIndex((s) => s === target);
  }

  completed(target: Status) {
    return this.currentIndex >= this.order.findIndex((s) => s === target);
  }

  canTransitionTo(target: Status) {
    return this.order.findIndex((s) => s === target) === this.currentIndex + 1;
  }

  hasUnlocked(target: Status) {
    return this.currentIndex >= this.order.findIndex((s) => s === target) - 1;
  }
}

const dataPoints: { title: string; fields: [string, (c: any) => any][] }[] = [
  {
    title: "Ideal Customer Profile",
    fields: [
      ["Platform", (c) => <PlatformIcon platform={c.platform} self />],
      ["Interest categories", (c) => c.categories],
      ["Creator locations", (c) => c.creator_locations],
      ["Creator gender", (c) => c.creator_gender],
      ["Creator age ranges", (c) => c.creator_age_ranges],
    ],
  },
  {
    title: "Campaign Brief",
    fields: [
      ["Agency", (c) => c.agency?.name],
      ["Brand", (c) => c.brand?.name],
      ["Objectives", (c) => c.objectives],
      ["About", (c) => c.about],
      ["Posting start date", (c) => c.posting_start_date],
      ["Posting end date", (c) => c.posting_end_date],
    ],
  },
  {
    title: "Creator Brief",
    fields: [
      ["Campaign purposes", (c) => c.campaign_purposes],
      ["Talking points", (c) => c.talking_points],
      ["Hashtags", (c) => c.hashtags],
      ["Required actions", (c) => c.required_actions],
      ["Add ons", (c) => c.add_ons],
      ["Partnership disclosures", (c) => c.partnership_disclosures],
      ["Additional guidelines", (c) => c.additional_guidelines],
      ["Creator sample", (c) => c.creator_sample],
      ["Need product back from creator", (c) => c.creator_sample_return],
    ],
  },
  {
    title: "Budget + Expected Results",
    fields: [
      ["Budget", (c) => formatCurrency(parseFloat(c.budget))],
      ["Number of Creators", (c) => c.budget_expected_results.creators],
      ["Number of UGC", (c) => c.budget_expected_results.ugc],
      ["Number of Posts", (c) => c.budget_expected_results.posts],
      ["Range of Creator Types", (c) => c.budget_expected_results.sizes],
      ["Available Audience Size", (c) => c.budget_expected_results.followers],
      ["Impressions", (c) => c.budget_expected_results.views],
      ["Cost per Viewer", (c) => c.budget_expected_results.cpv],
      ["Audience Engagement", (c) => c.budget_expected_results.engagement_rate],
    ],
  },
  {
    title: "System Info",
    fields: [
      ["Created by", (c) => c.user.name],
      ["Created", (c) => new Date(c.created_at).toLocaleString()],
    ],
  },
];

const AdminCampaignsShow = () => {
  const { data, unavailable, refetch } = usePageData();
  const [formObject, setFormObject] = useState<{ [key: string]: string }>();
  const { post, apiDelete } = useApiMutation();
  const FetchCreatorsDrawer = useFetchCreatorsDrawer();

  useDevTool(clickPrimaryButton);

  if (unavailable) {
    return unavailable;
  }

  const deletePackage = (pkg) => {
    apiDelete(pkg._link).then((res) => {
      if (res.data.success) {
        refetch();
        toast("Package was deleted");
      } else {
        genericError();
      }
    });
  };

  const { campaign } = data;
  const campaignStatus = new CampaignStatus(campaign);

  const setStatus = (status: Status) => {
    post(`/admin/campaigns/${campaign.uuid}/set_${status}`).then((res) => {
      if (res.data.success) {
        refetch();
        toast(`Campaign status updated: ${status}`);
      } else {
        genericError();
      }
    });
  };

  return (
    <div id="main" className="pb-10">
      <Breadcrumb>
        <Breadcrumb.Item href="/admin/campaigns" className="fs-14">
          <i className="bi bi-chevron-left small" /> Back to Campaigns
        </Breadcrumb.Item>
      </Breadcrumb>

      <h1>{campaign.name}</h1>

      <div className="mt-1 fs-14 text-muted">
        <PlatformIcon platform={campaign.platform} self />
        <span className="mx-2">•</span>
        {startCase(campaign.status)}
      </div>
      <Tabs className="mt-5">
        <Tab eventKey="Creators" title="Creators" className="pt-3">
          {campaign.creators_fetched_at ? (
            <DealsTab campaign={campaign} />
          ) : (
            <>
              <div className="alert alert-warning d-flex justify-content-between align-items-center">
                <div>
                  There are currently no creators matched. Select{" "}
                  <span className="fw-600">Fetch Creators</span> to start pulling creators
                  based on the agency's criteria.
                </div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => FetchCreatorsDrawer.show()}
                >
                  Fetch Creators
                </button>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey="Packages" title="Packages">
          <div className="mt-4">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                setFormObject({});
              }}
            >
              Add Package
            </button>
            {campaignStatus.isAtLeast("onboarding_complete") &&
              !campaignStatus.isAtLeast("packages_submitted") && (
                <button
                  className="btn btn-outline-primary btn-sm ml-1"
                  onClick={() => setStatus("packages_submitted")}
                  disabled={campaign.packages.length === 0}
                >
                  Set as Ready to Review
                </button>
              )}
          </div>
          <div className="mt-2">
            {campaignStatus.isAtLeast("packages_submitted") && (
              <div className="fs-14">
                Packages set on{" "}
                {new Date(campaign.packages_submitted_at).toLocaleString()}
              </div>
            )}
          </div>

          <table className="table mt-2">
            <thead>
              <tr>
                <th className="w-25 fs-13" />
                <th className="w-25 fs-13">Cost</th>
                <th className="w-25 fs-13">Creators</th>
                <th className="text-right" />
              </tr>
            </thead>
            <tbody>
              {campaign.packages?.map((p, i) => (
                <tr key={p.uuid}>
                  <td>
                    Package {p.index}
                    {p.id === campaign.selected_package_id && (
                      <Badge bg="warning" text="dark" className="ml-2">
                        Selected
                      </Badge>
                    )}
                  </td>
                  <td>
                    {parseFloat(p.cost).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                  <td>
                    {p.creator_platforms.map((p) => (
                      <div>
                        <PlatformIcon
                          platform={p.platform}
                          handle={p.handle}
                          key={p.uuid}
                        />
                      </div>
                    ))}
                  </td>
                  <td className="text-right">
                    <EllipsisMenu
                      actions={[
                        {
                          label: "View Package (agency)",
                          link: p.agency_link,
                          target: "_blank",
                        },
                        { separator: true },
                        {
                          label: "Edit",
                          action: () => setFormObject(p),
                        },
                        {
                          label: "Copy",
                          action: () => setFormObject({ ...p, uuid: null }),
                        },
                        {
                          label: "Delete",
                          className: "red",
                          action: () => deletePackage(p),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Tab>

        <Tab eventKey="Details" title="Details">
          {dataPoints.map((group) => (
            <div key={group.title} className="mt-5">
              <h3>{group.title}</h3>
              <table className="table table-sm table-striped mt-4">
                <tbody>
                  {group.fields.map(([label, value]) => (
                    <tr key={label}>
                      <td className="fw-700 w-350px">{label}</td>
                      <td className="pl-5">
                        {(() => {
                          const v = value(campaign);
                          if (v?.length === 0) {
                            return "--";
                          } else if (Array.isArray(v)) {
                            if (v.length > 1) {
                              return (
                                <ul>
                                  {v.map((i) => (
                                    <li key={i}>{i}</li>
                                  ))}
                                </ul>
                              );
                            } else {
                              return v[0];
                            }
                          } else {
                            return v;
                          }
                        })()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </Tab>
      </Tabs>

      <Drawer
        show={!!formObject}
        onHide={() => setFormObject(undefined)}
        title={`${formObject?.uuid ? "Edit" : "Add"} Package`}
      >
        <PackageForm
          object={formObject}
          close={() => setFormObject(undefined)}
          onSuccess={refetch}
          campaign={campaign}
        />
      </Drawer>
      <FetchCreatorsDrawer refetch={refetch} campaign={campaign} />
    </div>
  );
};

export default AdminCampaignsShow;
