import { useApiMutation } from "../../utils/api";
import { ModalBody } from "react-bootstrap";
import { makeModal, ModalBase } from "../../utils/make_modal";
import { SubmitButton } from "../../utils/submit_button";
import { TextField } from "../campaigns/fields";
import { useFormik } from "formik";
import { genericError } from "../../utils/errors";
import * as Yup from "yup";

const getSegment = (value: number) => {
  if (value < 1200) return "Below Market";
  if (value < 1900) return "Cost Efficient";
  if (value < 2500) return "Fair Value";
  return "Premium Price";
};

export const useCreatorOfferModal = () =>
  makeModal(
    ({ refetch, close, deal }: { refetch: () => void; close: () => void; deal: any }) => {
      const { post, saving } = useApiMutation();
      const formik = useFormik({
        initialValues: { creator_offer: null },
        validationSchema: Yup.object({
          creator_offer: Yup.number().required("Required").min(1, "Required"),
        }),
        onSubmit: (values) => {
          post(`${deal._link}/set_creator_offer`, {
            creator_offer: values.creator_offer,
          }).then(({ data }) => {
            if (data.success) {
              refetch();
              close();
            } else {
              genericError();
            }
          });
        },
      });

      const segment = getSegment(formik.values.creator_offer);

      return (
        <ModalBase title="Set your offer" close={close}>
          <ModalBody>
            <form onSubmit={formik.handleSubmit} className="">
              <label className="fs-20">Set your campaign price</label>
              <div className="flex align-items-center">
                <TextField
                  name="creator_offer"
                  type="number"
                  formik={formik}
                  inputClassName="fs-36"
                  prepend={<div className="fs-36">$</div>}
                />
              </div>

              <SubmitButton
                className="btn btn-primary w-100 mt-6"
                type="submit"
                loading={saving}
              >
                Propose offer
              </SubmitButton>
            </form>
          </ModalBody>
        </ModalBase>
      );
    }
  );
