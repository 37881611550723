import { Link } from "react-router-dom";
import { useApiGet } from "../../utils/api";
import CampaignCard from "./campaign_card";
import { PlatformIcon } from "../campaigns/utils";
import Tooltip from "../campaigns/tooltip";

const Dashboard = () => {
  const { data, unavailable } = useApiGet("/creators/dashboard");

  if (unavailable) {
    return unavailable;
  }

  const { creator, deals, todos, creator_platforms } = data;

  const image = creator_platforms.find((p) => p.image_url)?.image_url;

  return (
    <div>
      <div className="flex align-items-center">
        <div
          className="flex align-items-center justify-content-center"
          style={{ height: 60, width: 60, border: "1px solid #ccc", borderRadius: "50%" }}
        >
          {image ? (
            <img src={image} alt="profile" className="rounded-circle w-100" />
          ) : (
            <i className="bi bi-person-fill fs-32"></i>
          )}
        </div>
        <div className="ml-2">
          <div>{creator.name}</div>
          {creator_platforms.map((p) => (
            <Tooltip key={p.platform} tip={p.handle}>
              <PlatformIcon platform={p.platform} className="mx-1" />
            </Tooltip>
          ))}
          <Link to="/creators/creator_platforms" className="fs-12 d-block">
            Connect your social platforms
          </Link>
        </div>
      </div>

      <div className="mt-4">
        <div className="flex space-between">
          <h2 className="mb-1">Available Campaign Opportunities</h2>
          <Link to="/creators/deals">View All</Link>
        </div>
        {deals.map((deal) => (
          <CampaignCard deal={deal} key={deal.uuid} />
        ))}
      </div>

      <div className="mt-4">
        <h2 className="mb-1">Todos</h2>
        {todos.length === 0 && <div>No todos at this time</div>}
        {todos.map((todo) => (
          <Link
            to={todo.link}
            className="mv-2 d-block"
            style={{
              padding: "10px 20px",
              backgroundColor: "#f1f1f1",
              borderLeft: `4px solid ${todo.color}`,
            }}
          >
            <p className="black">{todo.title}</p>
            <div
              className={`badge mb-1 text-bg-${
                { orange: "warning", red: "danger" }[todo.color]
              }`}
            >
              {todo.badge}
            </div>
            <p className="gray">{todo.subtext}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
