import { campaignUuid } from "./utils";
import { Link } from "react-router-dom";
import { CampaignReviewBody } from "./review";
import { useApiGet } from "../../utils/api";

const Details = () => {
  const { data, unavailable } = useApiGet(`/campaigns/${campaignUuid()}/review`);

  if (unavailable) {
    return unavailable;
  }

  const { campaign } = data;

  return (
    <div id="main" className="container">
      <h1 className="text-center">{campaign.name}</h1>
      <h2 className="text-center mt-2">{campaign.brand.name}</h2>

      <div className="mt-5 form-container">
        <CampaignReviewBody campaign={campaign} editable={false} />
      </div>

      <div className="mt-14 mb-14 text-center">
        <Link to="/campaigns" className="btn btn-xw-secondary">
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default Details;
