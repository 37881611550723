import { useApiGet, useApiMutation } from "../../../utils/api";
import { campaignUuid, PlatformIcon } from "../../campaigns/utils";
import { DrawerBase, makeModal } from "../../../utils/make_modal";
import { toast } from "react-toastify";
import { SubmitButton } from "../../../utils/submit_button";
import { genericError } from "../../../utils/errors";
import { TextField } from "../../campaigns/fields";
import { useFormik } from "formik";
import { formatNummber } from "../../../utils/helpers";
import { capitalize } from "lodash";

export const useEditDealDrawer = () =>
  makeModal(
    ({
      refetch,
      close,
      object,
    }: {
      refetch: () => void;
      close: () => void;
      object: {
        offer: number;
        uuid: string;
        email: string;
        followers: number;
        size: string;
        gender: string;
        impressions: number;
        interactions: number;
        platform: string;
        handle: string;
      };
    }) => {
      const { put, saving } = useApiMutation();

      const formik = useFormik({
        initialValues: { creator_offer: object.offer || "" },
        onSubmit: (values) => {
          put(`/admin/campaigns/${campaignUuid()}/deals/${object.uuid}`, values).then(
            ({ data }) => {
              if (data.success) {
                refetch();
                close();
                toast(`Deal updated`);
              } else {
                genericError();
              }
            }
          );
        },
      });

      return (
        <DrawerBase title="Edit Deal" close={close}>
          <form onSubmit={formik.handleSubmit}>
            {[
              { label: "Handle", value: <PlatformIcon object={object} /> },
              { label: "Email", value: object.email },
              { label: "Followers", value: formatNummber(object.followers) },
              { label: "Size", value: capitalize(object.size) },
              { label: "Gender", value: capitalize(object.gender) },
              {
                label: "Expected impressions",
                value: formatNummber(object.impressions) || "--",
              },
              {
                label: "Expected interactions",
                value: formatNummber(object.interactions) || "--",
              },
            ].map(({ label, value }) => (
              <div className="mv-3" key={label}>
                <div className="fw-600">{label}</div>
                {value}
              </div>
            ))}

            <TextField
              label="Offer"
              name="creator_offer"
              className="mv-3"
              formik={formik}
              prepend="$"
            />

            <div className="mt-8 text-right">
              <button type="button" className="btn btn-outline-secondary" onClick={close}>
                Cancel
              </button>
              <SubmitButton loading={saving} className="btn btn-primary ml-2">
                Save
              </SubmitButton>
            </div>
          </form>
        </DrawerBase>
      );
    }
  );
