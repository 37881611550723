export const formatNummber = (amount: number) =>
  amount == null ? "" : amount.toLocaleString();

export const formatCurrency = (amount: number) =>
  amount == null
    ? ""
    : `$${amount.toLocaleString(undefined, {
        minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2,
      })}`;
