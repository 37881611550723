import _, { last, shuffle, startCase } from "lodash";
import { useEffect } from "react";

const LOREM =
  "Lorem ipsum dolor sit amet consectetur adipiscing elit sed " +
  " do eiusmod tempor incididunt labore et dolore magna aliqua enim" +
  " minim veniam quis nostrud exercitation ullamco laboris nisi aliquip" +
  " ea commodo consequat aute irure dolor in reprehenderit in voluptate" +
  " velit esse cillum dolore fugiat nulla pariatur Excepteur sint occaecat";

export const lorem = (c?: number) =>
  shuffle(LOREM.split(" "))
    .slice(0, c || 4)
    .join(" ");

export const randomName = (c?: number) => startCase(lorem(2));

const daysFromToday = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.toISOString().slice(0, 10);
};

const buildMockValues = () => ({
  platform: "tiktok",
  categories: ["Business Owner", "Lifestyle", "Tech"],
  creator_locations: ["United States", "United Kingdom"],
  creator_gender: "No Preferences",
  creator_age_ranges: ["no preference"],
  name: `${randomName()} Campaign`,
  brand_id: (
    document.querySelector(
      "[data-form-field=brand_id] option:last-child"
    ) as HTMLOptionElement
  )?.value,
  objectives: ["Increase Brand Awareness"],
  about: lorem(),
  posting_start_date: daysFromToday(25),
  posting_end_date: daysFromToday(35),
  campaign_purposes: ["Livestreams"],
  talking_points: "adipiscing tempor nisi occaecat",
  hashtags: lorem(),
  required_actions: lorem(),
  add_ons: ["Link in bio"],
  partnership_disclosures: ["Paid partnership label"],
  additional_guidelines: lorem(),
  creator_sample: "Yes",
  additional_instructions: lorem(),
  creator_sample_return: "No",
  budget: 10000,
});

const inViewport = (el) => {
  const { top, bottom } = el.getBoundingClientRect();
  const { innerHeight } = window;
  return top >= 0 && bottom <= innerHeight;
};

export const fillOutCampaign = (f) => {
  const mockValues = buildMockValues();
  const submitButton = document.querySelector(".btn-xw-primary") as HTMLButtonElement;

  const emptyFields = Array.from(
    document.querySelectorAll("[data-form-field]") as NodeListOf<HTMLDivElement>
  )
    .map((el) => el.dataset.formField as keyof typeof mockValues)
    .filter(
      (name) =>
        ["", "0", "null", "undefined"].includes(String(f.values[name])) &&
        mockValues[name]
    );

  if (emptyFields.length) {
    f.setValues({ ...f.values, ..._.pick(mockValues, emptyFields) });
    if (submitButton) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 300);
    } else {
      setTimeout(() => {
        f.handleSubmit();
      }, 200);
    }
  } else if (submitButton && !inViewport(submitButton)) {
    window.scrollTo(0, document.body.scrollHeight);
  } else if (submitButton) {
    submitButton.click();
  } else {
    // nothing left
  }
};

export const useCampaignDevTool = (formik?: any) => {
  useDevTool(fillOutCampaign, [formik]);
};

export const clickPrimaryButton = (selector?: string) => {
  const button = document.querySelector(
    selector || ".btn-xw-primary,.btn-primary"
  ) as HTMLButtonElement;

  if (!inViewport(button)) {
    window.scrollTo(0, document.body.scrollHeight);
    setTimeout(() => button.click(), 750);
  } else {
    button.click();
  }
};

(window as any).devToolStack = [];

export const useDevTool = (fn, args = []) => {
  const stack = (window as any).devToolStack;
  const name = new Error().stack.split("\n")[0];
  const item = { name, fn, args };
  const existing = stack.find((item) => item.name === name);
  if (existing) {
    Object.assign(existing, item);
  }

  useEffect(() => {
    stack.push(item);
    return () => stack.pop();
  }, []);
};
