import { capitalize } from "lodash";
import ActionButtons from "./action_buttons";
import { CheckboxGroup, MultiSelect, RadioGroup, Validators } from "./fields";
import { useFormikHandler } from "./form_handler";
import Header from "./header";
import { categories, platformCategories } from "./lists";
import CampaignInfo from "./steps_side_nav";
import { useCampaign, useShowOnboardingNav } from "./utils";

const CreatorProfile = () => {
  useShowOnboardingNav();
  const { campaign, unavailable } = useCampaign();

  const formik = useFormikHandler({
    campaign,
    defaultValues: {
      creator_gender: "No Preferences",
      creator_age_ranges: ["no preference"],
    },
    fields: ["categories", "creator_locations", "creator_gender", "creator_age_ranges"],
    validators: {
      categories: Validators.atLeastOneInGroup.max(
        5,
        "Select no more than 5 categories."
      ),
      creator_gender: Validators.requiredRadio,
      creator_age_ranges: Validators.atLeastOneInGroup,
    },
  });

  if (!campaign) {
    return unavailable;
  }

  return (
    <div id="main" className="container">
      <Header percent={20} />
      <CampaignInfo campaign={campaign} />

      <div className="row">
        <div className="col">
          <div className="page-subhead mb-5">
            <h1>Define Your Ideal Creators</h1>
            <h2>
              Set the attributes that matter most, and let our AI match the perfect
              creators for your campaign.
            </h2>
          </div>

          <form onSubmit={formik.handleSubmit} className="form-container">
            <div>
              <label>Creator Interests</label>
              <div className="form-text">
                Select up to 5 creator interests to help us source the best creators.
              </div>
              <MultiSelect
                options={(platformCategories[campaign.platform] || categories).map(
                  (c) => ({
                    label: capitalize(c),
                    value: c,
                  })
                )}
                name="categories"
                formik={formik}
                className="mt-2"
              />
            </div>

            <RadioGroup
              label="Creator gender"
              name="creator_gender"
              formik={formik}
              options={["Female", "Male", "Other", "No Preferences"]}
              className="mt-4"
            />

            <CheckboxGroup
              label="Creator age range(s)"
              name="creator_age_ranges"
              formik={formik}
              options={[
                { value: "no preference", exclusive: true },
                "18 - 24",
                "25 - 34",
                "35 - 44",
                "45 - 54",
                "55+",
              ]}
              className="mt-4"
            />

            <ActionButtons label="Match Creators" saving={formik.saving} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatorProfile;
