import { Modal } from "react-bootstrap";
import { useApiGet, useApiMutation } from "../../utils/api";
import { makeModal, ModalBase } from "../../utils/make_modal";
import { PlatformIcon } from "../campaigns/utils";
import { genericError } from "../../utils/errors";
import { SubmitButton } from "../../utils/submit_button";

const platforms = [
  {
    value: "tiktok",
    label: "TikTok",
    phylloId: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  },
  {
    value: "instagram",
    label: "Instagram",
    phylloId: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
  },
  {
    value: "youtube",
    label: "YouTube",
    phylloId: "14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
  },
  { value: "x", label: "X", phylloId: "7645460a-96e0-4192-a3ce-a1fc30641f72" },
];

export const useSimulateConnectModal = () =>
  makeModal(
    ({ refetch, close }: { refetch: () => void; deal: any; close: () => void }) => {
      const { data, unavailable } = useApiGet("/creators/creator_platforms/insight_iq");
      const { post } = useApiMutation();

      if (unavailable) {
        return (
          <ModalBase title="Connect Platform" close={close}>
            <Modal.Body className="p-15 text-center">
              <div
                className="loader"
                style={{ height: 50, width: 50, borderTopColor: "black" }}
              />
            </Modal.Body>
          </ModalBase>
        );
      }

      const { api_id, sdk_token } = data;

      const openPhylloSDK = (userId: any, token: any, platform: any) => {
        const workPlatformId = platforms.find((p) => p.value === platform)?.phylloId;
        const phyllo = (window as any).PhylloConnect.initialize({
          clientDisplayName: "Xrossworld",
          environment:
            (window as any).env.environment === "production" ? "production" : "sandbox",
          userId,
          token,
          workPlatformId,
        });

        phyllo.on("accountConnected", (accountId, workplatformId, userId) => {
          post("/creators/creator_platforms/connect", { accountId }).then((res) => {
            refetch();
            close();
          });
        });
        phyllo.on("accountDisconnected", (a: any, b: any, c: any) => {});
        phyllo.on("exit", (a: any, b: any) => {});
        phyllo.on("connectionFailure", (a: any, b: any, c: any) => genericError());
        phyllo.on("tokenExpired", (a: any) => genericError());

        phyllo.open();
      };

      const connect = (platform: any) => {
        openPhylloSDK(api_id, sdk_token, platform);
      };

      return (
        <ModalBase title="Connect Platform" close={close}>
          <Modal.Body>
            <div className="text-center">
              Choose a platform to connect
              {platforms.map((platform) => (
                <SubmitButton
                  key={platform.value}
                  className="btn btn-outline-primary w-100 mt-3"
                  onClick={() => connect(platform.value)}
                >
                  <PlatformIcon platform={platform.value} self />
                </SubmitButton>
              ))}
            </div>
          </Modal.Body>
        </ModalBase>
      );
    }
  );
