import { Link } from "react-router-dom";
import { useApiMutation, usePageData } from "../../utils/api";
import { formatDate, formatPlatform, PlatformIcon } from "../campaigns/utils";
import { Alert } from "react-bootstrap";
import { QuestionTooltip } from "../campaigns/tooltip";
import { CALL_TO_ACTIONS, CREATOR_OBJECTIVES } from "../campaigns/creator_brief";
import { useCreatorOfferModal } from "./offer_modal";

const formatList = (items) => (
  <ul>
    {items?.map((i) => (
      <li key={i}>{i}</li>
    ))}
  </ul>
);
const Campaign = () => {
  const { data, unavailable, refetch } = usePageData();
  const CreatorOfferModal = useCreatorOfferModal();
  const { post } = useApiMutation();

  if (unavailable) {
    return unavailable;
  }

  const { deal } = data;
  const { campaign } = deal;

  const submitted = deal.status !== "connected";

  const isMissingAccount = !deal.creator_platform.connected;

  return (
    <>
      {deal.status === "accepted_offer" && (
        <Alert variant="success">
          You have accepted the campaign offer. This does not guarantee the campaign.
        </Alert>
      )}
      {deal.status === "countered" && (
        <Alert variant="success">You have proposed an offer.</Alert>
      )}
      {deal.status === "accepted_for_campaign" && (
        <Alert variant="success">This campaign is in progress.</Alert>
      )}
      {deal.status === "invited" && (
        <Alert variant="warning" className="mt-4">
          <div className="fw-600">Social media account not connected.</div>
          <div>
            Please{" "}
            <Link to="/creators/creator_platforms">
              connect your {formatPlatform(deal.creator_platform.platform)} account
            </Link>
            <PlatformIcon object={deal.creator_platform} className="ml-1" /> to continue.
          </div>
        </Alert>
      )}

      <h2>{campaign.name}</h2>
      <div>{campaign?.agency?.name}</div>

      {isMissingAccount && (
        <Alert variant="warning" className="mt-4">
          <div>
            To bid on this campaign, please{" "}
            <Link to="/creators/creator_platforms">
              connect your {formatPlatform(deal.creator_platform.platform)} account:{" "}
            </Link>
            {deal.creator_platform.handle}
          </div>
        </Alert>
      )}

      <h3 className="mt-8 mb-3">Key Info</h3>

      <div className="fw-600">Campaign term</div>
      <div>
        {formatDate(campaign.posting_start_date)} -{" "}
        {formatDate(campaign.posting_end_date)}
      </div>
      <hr />
      <div className="fw-600">Platform</div>
      <div>
        <PlatformIcon platform={campaign.platform} self />
      </div>
      <hr />
      <div className="fw-600">About the campaign</div>
      <div>{campaign.about}</div>
      <hr />

      <h3 className="mt-4 mb-4">Details</h3>

      <div className="fw-600">Content style</div>
      <div>
        {formatList(
          campaign.campaign_purposes.map((item) => (
            <>
              {item}{" "}
              <QuestionTooltip
                tip={
                  CREATOR_OBJECTIVES.find((obj) => obj.value === item)?.description || ""
                }
              />
            </>
          ))
        )}
      </div>
      <hr />
      <div className="fw-600">Content types</div>
      <div>{formatList(campaign.content_types)}</div>
      <hr />
      <div className="fw-600">Objective</div>
      <div>{formatList(campaign.objectives)}</div>
      <hr />
      <div className="fw-600">Call to action</div>
      <div>
        {formatList(
          campaign.add_ons.map((item) => (
            <>
              {item}{" "}
              <QuestionTooltip
                tip={CALL_TO_ACTIONS.find((obj) => obj.value === item)?.description || ""}
              />
            </>
          ))
        )}
      </div>
      <hr />

      {submitted && (
        <>
          <h3 className="mt-8 mb-3">Campaign Cost</h3>
          <div className="fw-600">
            {deal.status === "accepted_for_campaign" && "Accepted amount"}
            {deal.status === "accepted_offer" && "Accepted amount"}
            {deal.status === "countered" && "Proposed offer"}
          </div>
          <div>${deal.creator_offer}</div>
        </>
      )}

      {!submitted && (
        <div className="mt-4">
          <button
            className="mt-3 btn btn-primary w-100"
            onClick={() => CreatorOfferModal.show()}
            disabled={isMissingAccount}
          >
            Set your offer
          </button>
        </div>
      )}

      <Link to="/creators" className="mt-4 d-block text-center">
        Back to Dashboard
      </Link>

      <CreatorOfferModal refetch={refetch} close={close} deal={deal} />
    </>
  );
};

export default Campaign;
