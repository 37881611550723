// Agency
import HomeIndex from "./home/index";
import CampaignsIndex from "./campaigns/index";
import CampaignsShow from "./campaigns/show";
import CampaignsIntro from "./campaigns/intro";
import CampaignsBudget from "./campaigns/budget";
import CampaignsPlatform from "./campaigns/platform";
import CampaignsContentTypes from "./campaigns/content_types";
import CampaignsCreatorProfile from "./campaigns/creator_profile";
import CampaignsCreatorsDiscovered from "./campaigns/creators_discovered";
import CampaignsBrief from "./campaigns/campaign_brief";
import CampaignsCreatorBrief from "./campaigns/creator_brief";
import CampaignsReview from "./campaigns/review";
import CampaignsDetails from "./campaigns/details";
import CampaignsCheckout from "./campaigns/checkout";
import PackagesShow from "./packages/show";
// Creators
import CreatorsDashboard from "./creators/dashboard";
import CreatorsRegistration from "./creators/registration";
import CreatorsCampaign from "./creators/campaign";
import CreatorsCampaigns from "./creators/campaigns";
import CreatorsOffer from "./creators/offer";
import CreatorsNda from "./creators/nda";
import CreatorsPlatforms from "./creators/platforms";
//Admin
import AdminImportCreators from "./admin/import_creators";
import AdminUsersIndex from "./admin/users/index";
import AdminCampaignsIndex from "./admin/campaigns/index";
import AdminCampaignsShow from "./admin/campaigns/show";
import AdminAgenciesIndex from "./admin/agencies/index";
import AdminCreators from "./admin/creators";
import AdminViewCreator from "./admin/creators/view_creator";
import AdminSystemSettings from "./admin/system_settings/index";

const AppRoutes = [
  ["/", HomeIndex],
  ["/campaigns", CampaignsIndex, "Dashboard"],
  ["/campaigns/:uuid", CampaignsShow],
  ["/campaigns/intro", CampaignsIntro, "Intro"],
  ["/campaigns/:uuid/budget", CampaignsBudget, "Budget"],
  ["/campaigns/:uuid/platform", CampaignsPlatform, "Platform"],
  ["/campaigns/:uuid/content_types", CampaignsContentTypes, "Content Types"],
  ["/campaigns/:uuid/creator_profile", CampaignsCreatorProfile, "Creator Profile"],
  ["/campaigns/:uuid/creators_discovered", CampaignsCreatorsDiscovered, "Matches"],
  ["/campaigns/:uuid/campaign_brief", CampaignsBrief, "Campaign Brief"],
  ["/campaigns/:uuid/creator_brief", CampaignsCreatorBrief, "Creator Brief"],
  ["/campaigns/:uuid/review", CampaignsReview, "Review"],
  ["/campaigns/:uuid/details", CampaignsDetails, "Details"],
  ["/campaigns/:uuid/packages/:packageId", PackagesShow, "Package"],
  ["/campaigns/:uuid/packages/:packageId/checkout", CampaignsCheckout, "Checkout"],
  // Creators
  ["/creators", CreatorsDashboard, "Dashboard"],
  ["/creators/registrations/:uuid", CreatorsRegistration],
  ["/creators/nda", CreatorsNda],
  ["/creators/deals", CreatorsCampaigns, "Campaigns"],
  ["/creators/deals/:uuid", CreatorsCampaign, "Campaign"],
  ["/creators/creator_platforms", CreatorsPlatforms, "Connect Platforms"],
  // Admin
  ["/admin/import_creators", AdminImportCreators],
  ["/admin/users", AdminUsersIndex],
  ["/admin/campaigns", AdminCampaignsIndex],
  ["/admin/campaigns/:uuid", AdminCampaignsShow],
  ["/admin/agencies", AdminAgenciesIndex],
  ["/admin/creators", AdminCreators],
  ["/admin/creators/:uuid", AdminViewCreator],
  ["/admin/system_settings", AdminSystemSettings],
];

export default AppRoutes;
